import React from 'react';
import { Typography, Container, Box, ImageList, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Landing from './landing';
import CustomDivider from './customDivider';
import lightTheme from '../themes/lightTheme';
import CardText from '../card/CardText';
import {CardTextTitle, CardTextParagraph} from '../card/CardText';
import {NavLink, Link} from 'react-router-dom';

function About() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isHalf = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  return (
    <Container maxWidth={false} sx={{ width: '100%' }}>
      <Landing />
      <CustomDivider startWidth={0} endWidth={100} color={lightTheme.palette.secondary.main} />

      <ImageList cols={isMobile ? 1 : (isHalf ? 2 : 3)} gap={0} sx={{alignItems:'center', overflowY: "visible", m:0}}>
        <CardText>
          <CardTextTitle>About</CardTextTitle>
          <CardTextParagraph>
            Hi again, I'm Pepijn; a Utrecht based game developer and software engineer.
            Find me on <a href="https://github.com/pepn" target="_blank" rel="noopener noreferrer">Github</a>,&nbsp;
            <a href="https://www.linkedin.com/in/pepijn-t/" target="_blank" rel="noopener noreferrer">LinkedIn</a>,&nbsp;
            <a href="https://lichess.org/@/Pepn" target="_blank" rel="noopener noreferrer">Lichess</a>, or send me an email!
          </CardTextParagraph>
        </CardText>
        <CardText>
          <CardTextTitle>Interests</CardTextTitle>
          <CardTextParagraph>
            I love design and any sort of puzzle, from strategy games such as chess to creating art that feels right, creating complex gamesystems, or just admiring a well designed room or building -- I'm all for it.</CardTextParagraph>
        </CardText>
        <CardText>
          <CardTextTitle>Mission</CardTextTitle>
          <CardTextParagraph>(GameSome) is the game studio dedicated to creating mere entertainment games & other applied interactive media that focuses on bettering humans and their lives. We aim to inspire positive change by bridging entertainment and goal-oriented design to create impactful sparks of joy, one game at a time.</CardTextParagraph>
        </CardText>
        <CardText>
          <CardTextTitle>Projects</CardTextTitle>
          <CardTextParagraph>Explore my professional & personal projects <NavLink to="/projects">here!</NavLink></CardTextParagraph>
        </CardText>
        <CardText>
          <CardTextTitle>TimeMachine</CardTextTitle>
          <CardTextParagraph>
            A personal timeline of my life, core memories, and experiences.
          </CardTextParagraph>
          <CardTextParagraph>
            Under Construction..
          </CardTextParagraph>
        </CardText>
        <CardText>
          <CardTextTitle>Blog</CardTextTitle>
          <CardTextParagraph>
            A collection of my thoughts, ideas, and insights on various topics.. Don't worry I have many, they just aren't there yet.
          </CardTextParagraph>
        </CardText>
      </ImageList>
      <Box sx={{ my: 5 }}>
        {/* Empty box with margin */}
      </Box>
    </Container>
  );
}

/*todo:
- split into 3 cards just like the projects page
- add a title on top of each card
- one card is interests, one is personal goals, one is skills
*/

export default About;
